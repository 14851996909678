import { request, noTimeOutReq } from '@/utils/request.js'

//考试异议分页查询
export function getOneReloadTestApi (examId) {
  return request({
    url: `/exam/score/achievement/confusion/${examId}/examinee/paper/get`,
    method: 'post',
  })
}

export function sumbitConfusionScoreApi (data) {
  return request({
    url: `/exam/score/achievement/confusion/evaluation/save`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data: data
  })
}
