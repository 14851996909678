<template>
  <div id="app">
    <el-card shadow="always">
      <div id="el-dialog__header">
        <span>开始评卷</span>
        <!-- <i class="el-icon-close" @click="exit"></i> -->
      </div>
      <!-- 视频组件 -->
      <dualVideoCom
        ref="dualVideoCom"
        :mainStreamUrl="mainStreamUrl"
        :subStreamUrl="subStreamUrl"
        :examOperationTest="examOperationTest"
        :showFace="showFace"
        @changeShowFace="(val)=>{
          showFace = val
        }"
      ></dualVideoCom>

      <el-row :gutter="5" id="markingVideoFormalTable">
        <el-col :span="12">
          <el-table
            v-loading="loading"
            stripe
            border
            ref="markingVideoTable"
            :data="leftOperationQuestionList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="index"
              label="序号"
              show-overflow-tooltip
              :width="56"
            ></el-table-column>
            <el-table-column label="评分项" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.operationQuestionName }}
                <el-tooltip
                  v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                  effect="dark"
                  :content="scope.row.markSuggest.remark"
                  placement="bottom"
                >
                  <i
                    class="el-icon-warning-outline"
                    style="float: right; line-height: 23px; font-size: 18px"
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              prop="score"
              label="分值"
              show-overflow-tooltip
              :width="61"
            ></el-table-column>
            <el-table-column label="操作" :min-width="60" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  v-throttle
                  size="small"
                  type="primary"
                  @click="fullMarking(scope.row)"
                  >满分</el-button
                >
                <el-button
                  v-throttle
                  size="small"
                  type="danger"
                  @click="zeroMarking(scope.row)"
                  >零分</el-button
                >
                <el-input
                  v-model="scope.row.inputScore"
                  style="width: 60px; margin-left: 10px"
                  @input="scoreInput(scope.row, scope.row.inputScore)"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-col>

        <el-col :span="12">
          <el-table
            v-loading="loading"
            stripe
            border
            ref="markingVideoTable"
            :data="rightOperationQuestionList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="index"
              label="序号"
              show-overflow-tooltip
              :width="56"
            ></el-table-column>
            <el-table-column label="评分项" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.operationQuestionName }}
                <el-tooltip
                  v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                  effect="dark"
                  :content="scope.row.markSuggest.remark"
                  placement="bottom"
                >
                  <i
                    class="el-icon-warning-outline"
                    style="float: right; line-height: 23px; font-size: 18px"
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="score"
              label="分值"
              show-overflow-tooltip
              :width="61"
            ></el-table-column>
            <el-table-column label="操作" :min-width="60" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  v-throttle
                  size="small"
                  type="primary"
                  @click="fullMarking(scope.row)"
                  >满分</el-button
                >
                <el-button
                  v-throttle
                  size="small"
                  type="danger"
                  @click="zeroMarking(scope.row)"
                  >零分</el-button
                >
                <el-input
                  v-model="scope.row.inputScore"
                  style="width: 60px; margin-left: 10px"
                  @input="scoreInput(scope.row, scope.row.inputScore)"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <el-row class="marking-video-footer">
        <span>
          考生得分：
          <span>{{ totalScore }}</span> 分
        </span>
        <el-button v-throttle size="medium" type="primary" @click="submitExit"
          >提交并退出</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="submitContinue"
          >提交并继续</el-button
        >
        <!-- <el-button v-throttle  size="medium" type="primary" @click="getNextTest">下一份</el-button> -->
        <el-button v-throttle size="medium" type="primary" @click="exit"
          >仅退出</el-button
        >
      </el-row>
    </el-card>
  </div>
</template>
<script>
import dualVideoCom from '@/components/dualVideoCom.vue'
import {
  getOneReloadTestApi,
  sumbitConfusionScoreApi,
} from '@/api/score/examObjectionMarking.js'
import { isEmpty, durationToTimeSting, throttle } from '@/utils/util'
export default {
  name: 'examObjectionMarking',
  components: {
    dualVideoCom,
  },
  data() {
    return {
      mainStreamUrl: '',
      subStreamUrl: '',
      loading: false,

      examId: '',
      subject: 0,
      examOperationTest: {},
      operationQuestionList: [],
      leftOperationQuestionList: [],
      rightOperationQuestionList: [],
      totalScore: 0,
      cellSelectedStyle:
        'border:1px solid rgba(255 143 143);text-align:center;',
      cellNoneStyle: 'border:0;text-align:center;',

      showFace: false
    }
  },

  mounted() {
    this.examId = this.$route.query.examId
    this.subject = this.$route.query.subject
    console.log(`examId:${this.examId}`)
    // this.initPlayer(this.mainStreamUrl, this.subStreamUrl)
    this.init()
    // this.$nextTick(() => {
    //   this.progressWidth = Math.floor(
    //     this.$refs.markingHandBoxProgressBar.clientWidth
    //   )
    // })
  },
  beforeDestroy() {
    // this.destroyPlayer()
  },
  methods: {
    init() {
      //获取一份试卷
      this.getOneTest()
    },
    getOneTest() {
      if (isEmpty(this.examId)) {
        return
      }
      getOneReloadTestApi(this.examId)
        .then((res) => {
          if (res.success) {
            this.renderData(res.data)
            // this.getExamineeVideo(res.data.examineeId)
            // 调组件
            this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
          } else {
            let msg = ''
            switch (res.code) {
              case '506':
                msg = '暂无待阅卷的考生'
                break
              case '801':
                msg = res.msg
                break
              case '805':
                msg = res.msg
                break
              case '809':
                msg = res.msg
                break
              case '821':
                msg = res.msg
                break
            }
            if (res.code == '506' || res.code == '821') {
              this.msgSuccess(msg)
              this.$router.push({
                path: `/examManage/examObjectionView`,
                query: {
                  examId: this.examId,
                  subject: this.subject,
                },
              })
            } else {
              this.msgWarning(msg)
            }
          }
        })
        .catch(() => {})
    },
    renderData(data) {
      this.examOperationTest = {}
      this.examOperationTest = data
      if (this.examOperationTest.examName.length > 25) {
        this.examOperationTest.aliasExamName =
          this.examOperationTest.examName.substring(0, 25) + '...'
      } else {
        this.examOperationTest.aliasExamName = this.examOperationTest.examName
      }
      this.examOperationTest.examNameLen =
        this.examOperationTest.examName.length
      this.mainStreamUrl = this.$imageAddress(
        this.examOperationTest.topCameraStream0
      )
      if (!isEmpty(this.examOperationTest.frontCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      } else if (!isEmpty(this.examOperationTest.sideCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.sideCameraStream0
        )
      } else {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      }

      this.$nextTick(() => {
        // this.$refs.dualVideoCom.destroyPlayer()
        this.$refs.dualVideoCom.initPlayer(
          this.mainStreamUrl,
          this.subStreamUrl
        )
        // 调组件
        // this.$refs.dualVideoCom.startPlayer(
        //   this.mainStreamUrl,
        //   this.subStreamUrl
        // )
// this.startPlayer(this.mainStreamUrl, this.subStreamUrl)
      })
      this.totalScore = 0
      this.operationQuestionList = []
      this.leftOperationQuestionList = []
      this.rightOperationQuestionList = []
      let listSize = this.examOperationTest.operationQuestions.length
      let splitPoint = Math.ceil(listSize / 2)
      this.examOperationTest.operationQuestions.forEach((item, index) => {
        item.index = index + 1
        this.$set(item, 'inputScore', item.score)
        this.operationQuestionList.push(item)
        this.totalScore += item.score
        if (index < splitPoint) {
          this.leftOperationQuestionList.push(item)
        } else {
          this.rightOperationQuestionList.push(item)
        }
      })
    },

    download() {},
    fullMarking(row) {
      this.$set(row, 'inputScore', row.score)
      this.computeScore(row)
    },
    zeroMarking(row) {
      this.$set(row, 'inputScore', 0)
      this.computeScore(row)
    },
    scoreInput(row, val) {
      if (isEmpty(val)) {
        this.$set(row, 'inputScore', '')
        this.computeScore()
        return
      }
      val = val.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')
      if (val > row.score) {
        val = row.score
      }
      this.$set(row, 'inputScore', val)
      this.computeScore()
    },
    computeScore() {
      let totalScore = 0
      this.operationQuestionList.forEach((item) => {
        if (isEmpty(item)) {
          return
        }
        if (isEmpty(item.inputScore)) {
          return
        }
        totalScore += Number(item.inputScore)
      })
      this.totalScore = totalScore.toFixed(1)
    },
    checkScore(txt) {
      let queryParam = this.getScoreMarkParam()
      try {
        queryParam.operationQuestions.forEach((item, index) => {
          if (isEmpty(item.score) || isNaN(item.score)) {
            throw new Error(`第${index + 1}行分数不能为空`)
          }
        })
        switch (txt) {
          case 'exit':
            this.updateScoreMark()
            break
          case 'continue':
            this.updateScoreMarkContinue()
            break
        }
      } catch (e) {
        this.msgWarning(e.message)
      }
    },
    //提交并退出
    submitExit() {
      this.checkScore('exit')
    },
    updateScoreMark() {
      let queryParam = this.getScoreMarkParam()
      if (isEmpty(queryParam)) {
        return
      }
      sumbitConfusionScoreApi(JSON.stringify(queryParam))
        .then((res) => {
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.$router.push({
                path: `/examManage/examObjectionView`,
                query: {
                  examId: this.examId,
                  subject: this.subject,
                },
              })
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    //提交并继续
    submitContinue: throttle(function () {
      this.checkScore('continue')
    }, 3000),
    updateScoreMarkContinue() {
      let queryParam = this.getScoreMarkParam()
      if (isEmpty(queryParam)) {
        return
      }
      // this.isPicture = false
      this.$refs.dualVideoCom.shutDownIsPicture()
      sumbitConfusionScoreApi(JSON.stringify(queryParam))
        .then((res) => {
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.getOneTest()
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    getScoreMarkParam() {
      if (this.operationQuestionList.length <= 0) {
        this.msgError('暂无数据')
        return
      }
      let operationQuestions = []
      this.operationQuestionList.forEach((item) => {
        let operationQuestion = {
          operationQuestionId: 0,
          operationQuestionName: '',
          score: '',
          sortNum: 0,
        }
        operationQuestion.operationQuestionId = item.operationQuestionId
        operationQuestion.operationQuestionName = item.operationQuestionName
        if (!isEmpty(item.inputScore)) {
          operationQuestion.score = Number(item.inputScore)
        }
        operationQuestion.sortNum = item.sortNum
        operationQuestions.push(operationQuestion)
      })
      let queryParam = {
        channel: 0,
        examId: this.examOperationTest.examId,
        examineeId: this.examOperationTest.examineeId,
        operationQuestions: operationQuestions,
        operationTestId: this.examOperationTest.operationTestId,
        review: this.examOperationTest.review,
        scoreType: this.examOperationTest.scoreType,
      }
      return queryParam
    },
    //仅退出
    exit() {
      this.globalMessageBox(
        `退出后不保留评卷记录，
      确定退出吗？`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          this.$router.push({
            path: `/examManage/examObjectionView`,
            query: {
              examId: this.examId,
              subject: this.subject,
            },
          })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-card__body {
  padding: 15px;
}

#el-dialog__header {
  margin-bottom: 10px;
}

#el-dialog__header > span {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
}

#el-dialog__header > i {
  float: right;
  font-size: 22px;
}

#exam-info-box {
  width: 100%;
  height: 50px;
  line-height: 50px;
  /*  position: absolute;
  top: 0; */
  background-color: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 200;
}

#marking-hand-box {
  width: 100%;
  height: 55px;
  /*  position: absolute;
  bottom: 0; */
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;
}

#marking-hand-box > img {
  margin-right: 10px;
  cursor: pointer;
}

.exam-info-badge {
  font: normal bolder 16px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #fff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marking-video-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marking-video-footer > span {
  color: #303133;
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
}

.marking-video-footer > span > span {
  color: #1890ff;
}

::v-deep .el-message-box > .el-message-box__btns > {
  text-align: center;
}

::v-deep .el-input__inner {
  height: 32px;
  text-align: center;
}

.marking-hand-box-progress-bar {
  width: 580px;
  height: 10px;
  line-height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 8px;
  position: relative;
  z-index: 0;
  background: #1890ff;
  cursor: pointer;
}
.marking-hand-box-progress-bar-point {
  width: 8px;
  height: 8px;
  border: 2px solid #1890ff;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
  //transition: all 2s ease;
}
.marking-hand-box-progress-bar-point-right {
  position: absolute;
  height: 10px;
  border-radius: 10px;
  z-index: 1;
  background: #ffffff;
  display: inline-block;
  right: 0;
  pointer-events: none;
  //transition: all 2s ease;
}

.marking-hand-box-progress-time {
  width: 150px;
  margin-right: 25px;
}

#markingVideoFormalTable ::v-deep .el-table .el-table__cell {
  padding: 6px 0;
}

.pointFrameImg {
  width: 100%;
  height: 100%;
  border: 1px solid #cbd8f5;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
